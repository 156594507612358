<template>
  <core-section id="what-ive-done">
    <abstract-3 />

    <core-heading>What I've done</core-heading>

    <v-col cols="12">
      <v-carousel
        :cycle="false"
        :height="height"
        :show-arrows="false"
        class="elevation-0"
        hide-delimiter-background
        :dark="$vuetify.theme.dark"
        :light="!$vuetify.theme.dark"
      >
        <v-carousel-item
          v-for="(project, i) in projects"
          :key="i"
        >
          <project :value="project" />
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </core-section>
</template>

<script>
  export default {
    name: 'WhatIveDone',

    components: {
      Abstract3: () => import('@/components/Abstract3'),
      Project: () => import('@/components/Project'),
    },
    data: () => ({
      projects: [
        {
          project: 'Business Analytics',
          name: 'Public Social Services',
          category: 'Data Visualization',
          src: 'summary-project.png',
          details: 'The goal of the project was to develop a dashboards for division and department metrics using business analytics service - Microsoft Power BI . Features and processes included Python, Power Query, and Data Analysis Expressions (DAX). ',
          date: '2021',
        },
        {
          project: 'Geospatial Analysis',
          name: 'Public Social Services',
          category: 'Geomapping',
          src: 'gis.png',
          details: 'Customer service delivery analysis utlizing ArcGIS Pro, StoryMap, and Esri ArcGIS online platform. Multiple GIS sources utilized, including GTFS transit feed, California Healthy Places Index, and American Community Survey (ACS) 5 year data. View at https://arcg.is/izPqu.',
          date: '2021',
        },
      ],
    }),
    computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 875
          case 'sm': return 875
          case 'md': return 915
          case 'lg': return 600
          case 'xl': return 600
        }
        return []
      },
    },
  }
</script>
